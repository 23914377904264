import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from '@reach/router';
import { toFullStateName, toMoney } from '../../helpers.js';
import { useLead } from "../../client"
import { isInstantPurchase, getPrincipalName, isMYDEligible } from "../../utils/business-logic";
import { useParams } from "@reach/router";

const TotalLine = styled.hr`
  float: right;
  width: 50%;
  margin: 7px 0;
`;
const BalanceDue = styled.span`
  font-size: 1.3em;
  color: #0d8bd1;
  font-weight: 400;
  float: right;
`;
const SmallText = styled.span`
  font-size: 0.8em;
`;

const PrincipalInfo = ({ company }) => (
  <div style={{ margin: '10px 0px' }}>
    <strong>Principal Information</strong>
    <div>{company.name}</div>
    <div>{company.address}</div>
    <div>
      {company.city}, {company.state} {company.zip}
    </div>
  </div>
);
const ContactInfo = ({ contact }) => (
  <div style={{ margin: '10px 0px' }}>
    <strong>Contact Information</strong>
    <div>{contact.firstName} {contact.lastName}</div>
    <div>{contact.phone}</div>
  </div>
);

const BondInfo = ({ customFields, effectiveDate, expirationDate }) => (
  <div style={{ margin: '10px 0px' }}>
    <strong>Bond Information</strong>
    {customFields.map((field, i) => {
      let { value } = field;
      if (field.type.toLowerCase() === 'date') {
        value = moment(value).format('MM/DD/YYYY');
      } else if (field.type.toLowerCase() === 'boolean') {
        value = value ? 'Yes' : 'No';
      }
      return (
        <div
          style={{
            display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
          }}
          key={`${field.display}-${i}`}
        >
          <div style={{ textAlign: 'left', marginRight: '10px' }}>{field.display}</div>
          <div style={{ textAlign: 'left' }}>{value}</div>
        </div>
      );
    })}
    <div style={{
      display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
    }}>
      <div style={{ textAlign: 'left', marginRight: '10px' }}>Term</div>
      <div style={{ textAlign: 'left' }}>{effectiveDate} - {expirationDate}</div>
    </div>
  </div>
);

const ShippingAddress = ({ shippingAddress }) => (
  <div style={{ margin: '10px 0px' }}>
    <strong>Shipping Address</strong>
    {/* <div>{shippingAddress.name}</div> */}
    <div>{shippingAddress.address}</div>
    <div>{shippingAddress.address2}</div>
    <div>
      {shippingAddress.city}, {shippingAddress.state} {shippingAddress.zipcode}
    </div>
  </div>
);

export const NewPurchaseSummary = () => {
  const { invoice, lead } = useLead()
  const { nonce } = useParams()
  const formattedContact = {
    firstName: lead.contacts[0].name.first,
    lastName: lead.contacts[0].name.last,
    phone: lead.contacts[0].phone,
  }
  return (
    <PurchaseSummary
      bondName={lead.bond?.application?.suretyBond?.name || lead.bond?.type}
      bondState={lead.bond.state}
      charges={invoice.charges}
      isMYD={isMYDEligible(lead)}
      MYDLength={lead.app.multiTerm}
      selectedShipping={lead.selectedShipping}
      balance={invoice.balance}
      lead={lead}
      company={{ name: getPrincipalName(lead), city: lead.company_city, state: lead.company_state, zip: lead.company_zip, address: lead.company_address, address2: lead.company_address2 }}
      nonce={nonce}
      isIP={isInstantPurchase(lead)}
      contact={formattedContact}
      showShipping={false}
      effectiveDate={moment(lead.bond.application.effectiveDate).format("MM/DD/YYYY")}
      expirationDate={moment(lead.bond.application.expirationDate).format("MM/DD/YYYY")}
    />
  )
}
const PurchaseSummary = (props) => {
  const {
    bondName,
    bondState,
    charges,
    isMYD,
    MYDLength,
    selectedShipping,
    balance,
    lead,
    shippingAddress,
    company,
    nonce,
    isIP,
    contact,
    showShipping,
    effectiveDate,
    expirationDate,
    isConfirmationPage
  } = props;

  const chargesJSX = [];
  function setCharges() {
    charges.forEach((charge, i) => {
      if (charge.category === 'Bond Premium' && isMYD) {
        chargesJSX.push(
          <div key={`${i}a`}>
            <span className="float-left">
              {charge.category} <SmallText> ({MYDLength} terms) </SmallText>
            </span>
            <span className="float-right">
              {toMoney(charge.amount, { noCents: false })}
            </span>
            <div className="clear-float"></div>
          </div>,
        );
      } else if (charge.category === 'Shipping' && (selectedShipping === 'Emailed' || selectedShipping === 'No Shipping Required')) {
        chargesJSX.push(
          <div key={`${1}b`}>
            <span className="float-left">{charge.category}</span>
            <span key={`${i}b`} className="float-right price shipping-charge">
              Not Applicable
            </span>
            <div className="clear-float"></div>
          </div>,
        );
      } else {
        chargesJSX.push(
          <div key={`${i}a`}>
            <span className="float-left">{charge.category}</span>
            <span className="float-right">
              {toMoney(charge.amount, { noCents: false })}
            </span>
            <div className="clear-float"></div>
          </div>,
        );
      }
    });
  }
  setCharges();

  return (
    <div className="purchase-summary">
      <h5 className="panel-title">Purchase Summary
        {isIP && (
          <Link style={{ float: 'right', fontSize: '14px' }} to={`/application/${nonce}/fill?edit=true`}>Edit</Link>
        )}
      </h5>
      {isIP && (
        <>
          <ContactInfo contact={contact}></ContactInfo>
          <PrincipalInfo company={company}></PrincipalInfo>
          <BondInfo customFields={lead.customFields} effectiveDate={effectiveDate} expirationDate={expirationDate}></BondInfo>
          {showShipping && (
            <ShippingAddress shippingAddress={shippingAddress}></ShippingAddress>
          )}
          <hr />
        </>
      )}
      {!isIP && !isConfirmationPage && (
        <BondInfo customFields={[]} effectiveDate={effectiveDate} expirationDate={expirationDate}></BondInfo>
      )}
      <strong>{`${toFullStateName(bondState)} ${bondName}`}</strong>
      <div>
        {chargesJSX}
        <div className="clear-float"></div>
        <div key="total">
          <TotalLine></TotalLine>
          <div className="clear-float"></div>
          <div>
            <BalanceDue>
              Balance Due{' '}
              <span style={{ paddingLeft: '3em' }}>
                {toMoney(balance, { noCents: false })}
              </span>
            </BalanceDue>
            <div className="clear-float"></div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default PurchaseSummary;
