import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import Axios from 'axios';
import { StateOptions } from '../../../helpers';
import { updateBondState, trackPanelSave, trackLeadMade } from '../../tracking';
import {
  PanelHeading,
  PanelWrapper,
  PanelTitle,
  PanelName,
  PanelIcon,
  PanelDetails,
  MobilePanelDetails,
  PanelEditButton,
} from './PanelStyles';

import { useFormState } from '../../../hooks';

import {
  FormInput,
  FormItem,
  FormSelect,
  FormLabel,
  SmartPageButton,
  ErrorMessage,
} from '../../../ExpressAppUI';

function ContactPanel({
  lead, dispatch, bondInfo, ...props
}) {
  const [complete, setComplete] = useState(false);
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const { data } = await Axios.post(`/application/${props.nonce}/save-contact-info`, { ...bondInfo, ...values, term: props.multiTerm });
      // We need to track if we made a lead on saving contact info
      if (data.madeLead && data.leadId) {
        trackLeadMade({ leadId: data.leadId, email: values.email, phone_number: values.phone, ...bondInfo });
      }
      dispatch({ type: 'UPDATE CONTACT INFO', ...values });
      setComplete(true);
      trackPanelSave('Contact Info');
    } catch {
      setShowError(true);
    } finally {
      setSubmitting(false);
    }
  };

  const form = React.useRef(null);
  const { isActive } = props;
  const formattedFields = {
    firstName: lead.name.first,
    lastName: lead.name.last,
    phone: lead.phone,
  };
  if (!bondInfo.bondState) {
    if (bondInfo.bondId === '50e1dcba67bb37bb4a00d564') {
      formattedFields.bondState = 'CA';
    } else {
      formattedFields.bondState = '';
    }
  }

  const requiredFields = Object.keys(formattedFields);
  // Grab our form state and event handlers
  const [
    values,
    // eslint-disable-next-line no-unused-vars
    touched,
    errors,
    formBlur,
    submitForm,
    { hasError },
  ] = useFormState(formattedFields, requiredFields, handleSubmit, form);

  React.useLayoutEffect(() => {
    if (values.bondState) {
      updateBondState(values.bondState);
    }
  }, [values.bondState]);
  return (
    <PanelWrapper active={isActive} className={`is-active-${isActive} instant-purchase-panel`}>
      <PanelHeading>
        <PanelTitle>
          <PanelIcon complete={complete} icon='phone'></PanelIcon>
          <PanelName>Contact Info</PanelName>
        </PanelTitle>
        {!isActive ? (
          <>
            <PanelDetails>
              <div>{values.firstName} {values.lastName}</div>
              <div>{values.phone}</div>
              <div>{values.email}</div>
            </PanelDetails>
            {complete && (
              <PanelEditButton onClick={() => {
                dispatch({ type: 'CHANGE ACTIVE STEP', activePanel: 'Contact' });
              }}>Edit</PanelEditButton>
            )}

          </>
        ) : null}
      </PanelHeading>
      {!isActive ? (
        <MobilePanelDetails>
          <div>
            <p>{values.firstName} {values.lastName}</p>
            <p>{values.phone}</p>
            <p>{values.email}</p>
          </div>
        </MobilePanelDetails>
      ) : null}
      {isActive && (
 <form action="" ref={form}>
 <FormItem>
   <FormLabel>Name</FormLabel>
   {errors.firstName || errors.lastName ? <p className="small-error">Please enter a first and last name</p> : ''}
   <FormInput
     onBlur={formBlur}
     required
     defaultValue={values.firstName}
     name={'firstName'}
     autoComplete="given-name"
     width="49%"
     margin="0 2% 0 0"
     placeholder="First"
     hasError={hasError('firstName')}
     data-track="Contact Info - First Name"
   ></FormInput>
   <FormInput
     onBlur={formBlur}
     name={'lastName'}
     defaultValue={values.lastName}
     required
     autoComplete="family-name"
     width="49%"
     placeholder="Last"
     hasError={hasError('lastName')}
     data-track="Contact Info - Last Name"
   ></FormInput>
 </FormItem>
   <FormItem>
     <FormLabel>
       Phone<sup>*</sup>
     </FormLabel>
     {errors.phone && <p className="small-error">{errors.phone}</p>}
     <MaskedInput
       mask={[
         '(',
         /\d/,
         /\d/,
         /\d/,
         ')',
         ' ',
         /\d/,
         /\d/,
         /\d/,
         '-',
         /\d/,
         /\d/,
         /\d/,
         /\d/,
       ]}
       className={`${hasError('phone') ? 'has-error' : ''} form-input`}
       name={'phone'}
       defaultValue={values.phone}
       required
       width="49%"
       placeholder="(000) 000-0000"
       data-inputtype="phone"
       onBlur={formBlur}
       data-track="Contact Info - Phone"
     />
   </FormItem>
   <FormItem>
<SensitiveEmailInput 
  formBlur={formBlur}
  hasError={hasError}
/>
{errors.email && <p className="small-error">{errors.email}</p>}

{!bondInfo.bondState && (
  <FormItem>
    <FormLabel>What state is the bond for?</FormLabel>
    {errors.bondState && <p className="small-error">{errors.bondState}</p>}

 <FormSelect
 name={'bondState'}
 defaultValue={values.bondState || ''}
 required
 hasError={hasError('bondState')}
 onChange={formBlur}
 data-track={'Contact Info - Bond State'}
>
 <option value="" disabled>
    Select State
 </option>
 {StateOptions.map((option) => (
   <option
     key={`bondState-${option.value}`}
     value={option.value}
     disabled={((bondInfo.bondId === '50e1dcba67bb37bb4a00d564' && option.display !== 'California') || (bondInfo.bondId === '589b962c6e43e8311cc03338' && option.display === 'California'))}
   >
     {option.display}
   </option>
 ))}
</FormSelect>
  </FormItem>

)}
</FormItem>
<SmartPageButton
  className='instant-purchase-panel-button'
  buttonText='Save & Continue'
  onClickFunction={submitForm}
  isSubmitting={submitting}
  track={'Contact Info - Save'}
>
</SmartPageButton>
{showError ? (
  <ErrorMessage>
    An error has occurred when trying to save your information. Please reload the page and try again. If you keep getting this error message, please give us a call at <a href='tel:+18003084358'> 1(800)308-4358 </a>
  </ErrorMessage>
) : null}

</form>
      )}

    </PanelWrapper>
  );
}

function SensitiveEmailInput({hasError, formBlur}){
  const [updatingEmail, setUpdatingEmail] = useState(false)
  if(updatingEmail) {
    return (
      <>
        <FormLabel>
          Email<sup>*</sup>
          <i className="sub-label">
            Confidential documents will be sent to this address.
          </i>
        </FormLabel>
        <FormInput
          name="email"
          defaultValue=""
          required
          placeholder="name@example.com"
          hasError={hasError('email')}
          onBlur={formBlur}
          data-inputtype="email"
          data-track="Contact Info - Email"
        />
      </>
    )
  } else {
    return(
      <>
      <div style={{
        display:"flex",
        justifyContent: "space-between",
        alignItems: "start"
      }}>
        <FormLabel>
          Email<sup>*</sup>
          <i className="sub-label">
            Your email address has been securely stored. Confidential documents will be sent to this address. Click edit to update the email address on file.
          </i>
        </FormLabel>
        <button
          type="button"
          onClick={()=> setUpdatingEmail(true)}
          className="button-effective-today"
          style={{width:"80px", cursor: 'pointer'}}

        >
          Edit
        </button>
      </div>
      <FormInput 
        key="fakeinput"
        name={"disabledInput"}
        value={"**********"}
        disabled={true}
      />
    </>
    )
  }
}

export default ContactPanel;
